<template>
  <div class="page">
    <navbar index="1" />

    <!-- banner begain -->    
    <banner>
      <img src="../assets/img/banner_about.jpg" class="img-fluid" alt="诚派科技南京有限责任公司">
    </banner>
    <!-- banner end -->

    <div class="main-content">

      <!-- 关于我们 begain -->
      <div class="about-box" id="about">
        <div class="about-content">
          <section-title title="ABOUT US /关于诚派" />
          <div class="about-info">
            <p>诚派科技专注于移动互联网领域的研发与大数据商业化应用。专业的团队为客户提供设计咨询,定制研发,数据分析，技术运维等一体化综合解决方案。</p>
            <p>来自行业的精英为国内外知名企业提供包括智慧城市、能源、移动电商、教育培训、传媒出版、移动办公、政务系统、医疗、金融、社交,快消品等领域的专业支持。</p>
            <p>目前公司研发团队总人数为40人, 70%的人员从业经验超过6年。90%的员工具有计算机相关专业本科以上学。</p>
            <p>公司总部为南京;在上海设有研发中心和分公司。上海公司名称为:上海耀腾科技发展有限公司。</p>
          </div>
        </div>
        <div class="about-img">
          <img src="../assets/img/about_1.jpg" alt="">
        </div>
      </div>
      <!-- 关于我们 end -->

      <!-- 荣誉资质 begain -->
      <div class="honors-box">
        <section-title title="HONORS /荣誉资质" />
        <div class="honors-content">
          <div class="content-top">
            <div class="honors-item">
              <div class="item-img"><img src="../assets/img/honors_1.jpg" alt=""></div>
              <div class="item-name">2018年被评为软件企业</div>
            </div>
            <div class="honors-item">
              <div class="item-img"><img src="../assets/img/honors_2.jpg" alt=""></div>
              <div class="item-name">2019年被评为高新技术企业</div>
            </div>
          </div>
          <div class="content-bottom">
            <div class="honors-item">
              <div class="item-img"><img src="../assets/img/honors_3.jpg" alt=""></div>
              <div class="item-name">软件营作权</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 荣誉资质 end -->

      <!-- 公司文化 begain -->
      <div class="culture-box" id="culture">
        <section-title title="CORPORATE CULTURE／公司文化 " />
        <div class="culture-content">
          <div class="culture-item">
            <div class="item-img">
              <img src="../assets/img/culture_1.jpg" alt="">
            </div>
            <div class="item-txt">
              <div class="title">企业愿景</div>
              <div class="desc">诚派科技以“打造专业线性价比最高"为核心愿景。</div>
            </div>
          </div>
          <div class="culture-item">
            <div class="item-img">
              <img src="../assets/img/culture_2.jpg" alt="">
            </div>
            <div class="item-txt">
              <div class="title">企业价值观</div>
              <div class="desc">聚焦客户关注点，提供最快最新行业解决方案，持续为客户创造最大价值。</div>
            </div>
          </div>
          <div class="culture-item">
            <div class="item-txt">
              <div class="title">企业使命</div>
              <div class="desc">成就员工梦想为客户创造财富。</div>
            </div>
            <div class="item-img">
              <img src="../assets/img/culture_3.jpg" alt="">
            </div>
          </div>
          <div class="culture-item">
            <div class="item-txt">
              <div class="title">经营理念</div>
              <div class="desc">诚派科技以“诚信合作，勇于创新”作为企业核心经营理念。</div>
            </div>
            <div class="item-img">
              <img src="../assets/img/culture_4.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
      <!-- 公司文化 end -->

      <!-- 行业合作 begain -->
      <div class="industry-box" id="industry">
        <section-title title="CORPORATE CULTURE／行业合作" />
        <div class="industry-content">
          <div class="industry-map container-fluid text-center">
            <img src="../assets/img/industry_map.jpg" class="img-fluid" alt="..." />
          </div>
          <div class="container-fluid industry-txt">
            <div class="row  text-center">
              <div class="col-md-2 offset-md-1">
                <div class="title"><span>A</span>电子商务</div>
                <div class="desc">
                  <div class="desc-txt">智慧牛城</div>
                  <div class="desc-txt">鼎玉铉</div>
                  <div class="desc-txt">御品惠</div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="title"><span>B</span>教育</div>
                <div class="desc">
                  <div class="desc-txt">零网智能教育系统学生端</div>
                  <div class="desc-txt">零网智能教育系统教师端</div>
                  <div class="desc-txt">零网智能教育系统局管理端</div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="title"><span>C</span>医药</div>
                <div class="desc">
                  <div class="desc-txt">连云港迈克生物药剂管理系统</div>
                  <div class="desc-txt">淮安人民系统门诊管理系统</div>
                  <div class="desc-txt">吉安医院器官捐献系统及报表管理系统</div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="title"><span>D</span>智慧化管理</div>
                <div class="desc">
                  <div class="desc-txt">智能洗车机管理系统</div>
                  <div class="desc-txt">智能停车管理系统</div>
                  <div class="desc-txt">警犬智能检测系统</div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="title"><span>E</span>农业</div>
                <div class="desc">
                  <div class="desc-txt">多吃菜</div>
                  <div class="desc-txt">智慧能效及碳足迹</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 行业合作 end -->

      <!-- 合作伙伴 begain -->
      <div id="partner">
        <partner />
      </div>
      <!-- 合作伙伴 end -->

      <!-- 联系我们 begain -->
      <div id="contact">
        <contact />
      </div>
      <!-- 联系我们 end -->

    </div>

    <foot />
  </div>
</template>

<script>
  import navbar from '@/components/navbar'
  import banner from '@/components/banner'
  import partner from '@/components/partner'
  import contact from '@/components/contact'
  import foot from '@/components/footer'
  import sectionTitle from '@/components/sectionTitle'
  import {locatePoint} from '@/mixin/locatePoint'
  export default {
    name: 'About',
    mixins: [locatePoint],
    mounted () {
      this.$nextTick(() => {
        this.LP();
      })
    },
    components: {
      navbar,
      banner,
      sectionTitle,
      partner,
      contact,
      foot
    }
  }
</script>

<style lang="scss" scoped>
  .main-content {
    padding-top: 60px;
    .about-box {
      margin: 0 auto;
      padding: 60px 0;
      width: 1200px;
      display: flex;
      justify-content: space-between;
      .about-content {
        padding-top: 80px;
        width: 550px;
        .about-info {
          margin-top: 65px;
          line-height: 1.5;
          font-size: 20px;
          color: #666;
          p {
            margin: 0;
            text-indent: 2em;
          }
        }
      }
      .about-img {
        padding-top: 20px;
        width: 500px;
        img {
          width: 100%;
        }
      }
    }
    .honors-box {
      margin: 0 auto;
      padding: 60px 0;
      width: 1200px;
      .honors-content{
        margin-top: 96px;
        .honors-item {
          .item-img {
            width: 500px;
            height: 367px;
            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }
          .item-name {
            margin-top: 25px;
            line-height: 1;
            text-align: center;
            font-size: 16px;
            color: #666;
          }
        }
        .content-top {
          display: flex;
          justify-content: space-between;
          align-items: stretch;
        }
        .content-bottom {
          margin-top: 120px;
          .item-img {
            width: 100%;
          }
        }
      }  
    }
    .culture-box {
      margin: 0 auto;
      padding: 60px 0;
      width: 1200px;
      .culture-content {
        margin-top: 87px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
        box-shadow: 0  0 5px 1px rgba(0, 0, 0, .2);
        .culture-item {
          flex: 0 0 50%;
          display: flex;
          cursor: pointer;
          .item-img {
            flex: 0 0 300px;
            width: 300px;
            height: 197px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .item-txt {
            flex: 1;
            padding: 0 20px;
            .title {
              margin-top: 30px;
              margin-bottom: 20px;
              padding-left: 15px;
              line-height: 1;
              position: relative;
              font-size: 24px;
              color: #2663FF;
              &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                display: inline-block;
                width: 6px;
                height: 100%;
                background-color: #2663FF;
                border-radius: 4px;
              }
            }
            .desc {
              line-height: 1.5;
              font-size: 18px;
              color: #666;
            }
          }
          &:hover {
            .item-txt {
              background-image: linear-gradient(0deg, #5A9CD9 0%, #4770CA 100%);
              .title {
                color: #fff;
                &::before {
                  background-color: #fff;
                }
              }
              .desc {
                color: #fff;
              }
            }
          }
        }
      }
    }
    .industry-box {
      padding: 60px 0;
      .industry-map {
        margin-top: 120px;
      }
      .industry-txt {
        margin-top: 120px;
        color: #3e3f3f;
        .title {
          margin-bottom: 30px;
          font-size: 26px;
          span {
            margin-right: 10px;
            width: 34px;
            height: 34px;
            line-height: 34px;
            display: inline-block;
            border-radius: 3px;
            font-size: 24px;
            font-weight: bold;
            background: linear-gradient(0deg, #5A9CD9, #4773CB);
            color: #fff;
          }
        }
        .desc {
          line-height: 1.4;
          font-size: 20px;
          .desc-txt {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
</style>