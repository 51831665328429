<template>
  <div class="banner">
    <slot />
    <h1>{{title}}</h1>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style lang="scss">
  .banner {
    position: relative;
    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 1;
      font-size: 60px;
      color: #fff;
    }
  }
</style>