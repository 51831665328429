export const locatePoint = {
  data () {
    return {}
  },
  methods: {
    LP (hash = '') {
      const id = (hash == '' ? this.$route.hash : hash).split('#')[1]
      setTimeout(() => {
        const ele = document.getElementById(id)
        ele && ele.scrollIntoView(true)
      })
    }
  }
}